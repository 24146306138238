import React from 'react';
import '../../css/App.css';
import 'semantic-ui-css/semantic.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

function Flags() {
    var countries = ['in', 'gb', 'gb-eng', 'gb-sct','mu','fr','ch','it','va','be','ca']
    var flags = []
    for(var i=0; i < countries.length; i++) {
        var cd = countries[i];
        var src_url = "https://flagcdn.com/h40/".concat(cd).concat(".png") 
        var srcset_url="https://flagcdn.com/h80/".concat(cd).concat(".png 2x")
        flags.push(<img src={src_url} srcset={srcset_url} width="45" height="30" paddingBottom="5px" />)
        flags.push("\t")
    }
    return flags;
}

function Hobbies(){
    return(
        <React.Fragment>
        <div className = "hobbies" id="hobbies">
            <div className="row">
                <div className="col-sm-5" id="otherHobbies">
                        <h1 id="badmintonHeader" style={{fontSize: 40, fontWeight: "bold", textAlign: "center"}}>I also <FontAwesomeIcon icon={faHeart} style={{color:"maroon"}} /> Badminton<br></br></h1>
                                <p style={{fontSize: 16}}> Not only do I play the sport, it's also the only sport that I actually watch. I witnessed the live action at 
                                the <a href="https://www.birmingham2022.com/">XXII Commonweath Games finals</a> in 2022 & the <a href="https://www.allenglandbadminton.com/">All England Badminton Championship</a> 2023 semi-finals (and they were
                                unreal).</p>
                        <br></br>
                </div>
                <div className="col-sm-7" id="otherHobbies">
                    <p>My other eclectic interests include <b>crosswords</b> (or any word games for that matter), <b>trivia quizzes</b>, <b><a href="https://www.etymonline.com/">etymologies</a></b>, <b>puns</b>, <b><a href="https://www.youtube.com/playlist?list=PLR7XO54Pktt8_jNjAVaunw1EqqcEAdcow">countries & flags & such things</a></b> (I 
                       spent more time with the dictionary and the atlas as a kid instead of storybooks), and <b>aeroplanes</b></p>
                    <h1 id="countryHeader" style={{fontSize: 16, fontWeight: "bold"}}>Travelling to different countries enthralls me as well<br></br></h1>
                    <br></br>
                    <p className="flags">{Flags()}</p>
                </div>
            </div>
        </div>
        </React.Fragment>
    );
}

export default Hobbies;